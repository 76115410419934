import React, { useEffect, useState } from "react";
import * as API from "../utils/api";
import queryString from "query-string";
import { useDispatch, useStore, useSelector } from "react-redux";
import _ from "underscore";
import Grid from "@mui/material/Grid";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SEO from "../components/seo";

// import DogMp4 from "../videos/test_video.mp4"
import "@fontsource/poppins";
import "../components/app.css";
import NavigationTabs from "../components/tabs/tabs";
import Menu from "../components/general/menu";
import MyEvents from "../components/feed/myEvents";
import { navigate } from "gatsby";
import Layout from "../components/layout";
import Loading from "../components/general/loading";

const TABS = [
  { label: "Upcoming", value: "upcoming" },
  { label: "Past", value: "past" },
  // { label: "Discover", value: "discover" },
];

const IndexPage = (props) => {
  const initialTab = queryString.parse(props.location?.search).tab;
  const [tab, setTab] = useState(!!initialTab ? initialTab : "upcoming");
  const store = useStore();
  const state = store.getState();

  const userId = useSelector((state) => state?.auth?.userId, _.isEqual);
  const loggedInUser = useSelector((state) => state?.user?.user, _.isEqual);
  const [isLoading, setIsLoading] = useState(false);
  const [isPastLoading, setIsPastLoading] = useState(false);
  const [myUpcomingEvents, setMyUpcomingEvents] = useState([]);
  const [open, setIsOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchFeedData = async () => {
      setIsLoading(true);
      try {
        await dispatch.user.getUser(userId);
        await dispatch.user.getUpcomingEvents();
      } catch (e) {
        let message = "There was an error";
        if (!!e.message && e.message?.length > 0) {
          message = message + e.message;
        }
        setSnackbarMessage(message);
        setIsOpen(true);
        console.log(e);
      }
      setIsLoading(false);
    };

    fetchFeedData();
    return async () => {
      //  TODO: If i want to clear any data on unmount
    };
  }, [userId, dispatch.user]);

  useEffect(() => {
    const fetchFeedData = async () => {
      setIsPastLoading(true);
      try {
        await dispatch.user.getUser(userId);
        await dispatch.user.getPastEvents();
      } catch (e) {
        let message = "There was an error";
        if (!!e.message && e.message?.length > 0) {
          message = message + e.message;
        }
        setSnackbarMessage(message);
        setIsOpen(true);
        console.log(e);
      }
      setIsPastLoading(false);
    };

    fetchFeedData();
    return async () => {
      //  TODO: If i want to clear any data on unmount
    };
  }, [userId, dispatch.user]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage("");
    setIsOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleUpcomingAttendanceUpdate = async (status, id) => {
    const payload = { user_id: userId, event_id: id, status: status };
    try {
      await API.updateAttendance(payload);

      const upcomingEvents = await dispatch.user.getUpcomingEvents();
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackbarMessage(message);
      setIsOpen(true);
      return;
    }
  };

  const homeOptions = [
    // TODO: uncomment when search and account are implemented
    // {
    //   label: "Search",
    //   handleClick: () => {},
    //   hasIcon: true,
    // },
    // {
    //   label: "Account",
    //   handleClick: () => {},
    //   hasIcon: true,
    // },
    {
      label: "Profile",
      handleClick: () => {
        navigate(`/profile/?id=${userId}`);
      },
      hasIcon: true,
    },
    {
      label: "Logout",
      handleClick: () => {
        dispatch.auth.logOut();
      },
      hasIcon: true,
    },
  ];

  return (
    <Layout>
      {/* TODO: determine if user is logged in */}
      {/* TODO: determine if user is on mobile or desktop */}

      <Grid
        container
        direction="column"
        style={{ height: "100vh", width: "100%" }}
      >
        <Grid item style={{ width: "100%", marginBottom: 15 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid
              item
              style={{
                backgroundColor: "#212121",
                width: 30,
                height: 30,
                borderRadius: 3,
              }}
            >
              <Menu options={homeOptions} />
            </Grid>
            <NavigationTabs
              handleChange={handleChange}
              value={tab}
              tabs={TABS}
            />
            <Grid
              item
              style={{
                width: 35,
                height: 35,
                marginRight: 5,
                marginTop: 5,
              }}
            >
              <div style={{width: 35}}/>
              {/* <AddBoxIcon
                style={{ width: 35 }}
                onClick={() => navigate("/create/")}
              /> */}
            </Grid>
          </Grid>
        </Grid>
        {!isLoading ? (
          <>
            <MyEvents tab={tab} />
          </>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "85%", width: "100%" }}
          >
            <Grid item style={{ width: "90%" }}>
              {" "}
              <Loading />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
        key={"top" + "center"}
      />
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
