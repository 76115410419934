import React from "react";
import { useSelector } from "react-redux";
import { NO_EVENTS_HELPER_TEXT } from "../../tools/appText";
import EventCard from "../eventCard";
import _ from "underscore";
import Grid from "@mui/material/Grid";


export default function MyEvents(props) {
  const userId = useSelector((state) => state?.auth?.userId, _.isEqual);
  const upcomingEvents = useSelector(
    (state) => state?.user?.upcomingEvents,
    _.isEqual
  );
  const pastEvents = useSelector((state) => state?.user?.pastEvents, _.isEqual);
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ width: "100%", paddingLeft: 20, paddingRight: 20 }}
    >
      {(props.tab === "upcoming" && upcomingEvents?.length > 0) ||
      props.tab === "past" ? (
        <Grid item style={{ width: "100%", height: "78vh" }}>
          <Grid item style={{ marginTop: 10, overflow: "none" }}>
            <Grid
              className="events"
              container
              style={{
                flex: "1 1 auto",
                whiteSpace: "nowrap",
                overflowY: "scroll",
                maxHeight: "77vh",
              }}
            >
              {props.tab === "upcoming" &&
                upcomingEvents?.map((event) => (
                  <EventCard
                    event={event}
                    userId={userId}
                  />
                ))}
              {props.tab === "past" &&
                pastEvents?.map((event) => (
                  <EventCard event={event} userId={userId} />
                ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          {/* TODO: update the text in app text */}
          <div style={{ marginBottom: 20 }}>{NO_EVENTS_HELPER_TEXT}</div>
        </>
      )}
    </Grid>
  );
}
